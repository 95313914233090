(function($) {
    let $fn = $("#layout_header");

    if ($fn.length) {

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom($fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    }
})(jQuery);