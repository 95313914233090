(function ($) {
    let $fn = $(".comp_references");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]"),
            autoplay = $slider.data("slider");

        if($slider.children().length > 1){
            $.lib_flickity(function () {
                $slider.flickity({
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: true,
                    cellAlign: "left",
                    contain: true,
                    autoPlay: autoplay,
                })
            })
        }
    }
})(jQuery);