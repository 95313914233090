(function($) {
    const fn = ".part_ui_alert";

    doc.on("click", `${fn} [data-click-dismiss]`, function(){
        let elm = $(this);
        elm.closest(fn).fadeOut(300);
        setTimeout(function() {
            elm.closest(fn).remove();
        },300);
    });
})(jQuery);