(function ($) {
    let $fn = $(".comp_gallery");

    if ($fn.length) {
        let $gallery = $fn.find("[data-gallery-list]"),
            $nav = $fn.find(".wrp_comp_nav");

        $gallery.createGallery();

        $nav.on("click",".part_ui_btn",function () {
            $nav.find(".mod--active").removeClass("mod--active");
            $(this).addClass("mod--active");

            //AJAX gallery, call createGallery afterwards
        })
    }
})(jQuery);