(function($){
    const $fn = $(".comp_visual");

    if ($fn.length) {
        $.lib_flickity(function() {
            $.importScript(cdnjs.flickity_sync,function () {
                $fn.each(function () {
                    let $el = $(this);
                    let $slider = $el.find('[data-visual-slider]'),
                        $caption = $el.find('[data-visual-caption]'),
                        autoplay = $slider.data("visual-slider");

                    if($slider.children().length > 1) {
                        $slider.flickity({
                            imagesLoaded: false,
                            percentPosition: false,
                            setGallerySize: false,
                            pageDots: false,
                            wrapAround: true,
                            contain: true,
                            prevNextButtons: false,
                            autoPlay: autoplay,
                            pauseAutoPlayOnHover: true,
                            sync: $caption[0]
                        });

                        $caption.flickity({
                            contain: true,
                            pageDots: false,
                            percentPosition: false,
                            prevNextButtons: true,
                            wrapAround: true,
                            adaptiveHeight: true
                        });
                    }
                })
            });
        });

        $fn.on("click","[data-scroll]",function () {
            $('html, body').animate({
                scrollTop: $fn.next("section").offset().top - $("#layout_header").innerHeight()
            }, 500);
        });
    }
})(jQuery);