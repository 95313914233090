jQuery.lib_flickity = function(callback) {
    if (!html.hasClass("ie")) {
        $.importScript(cdnjs.flickity, function() {
            if (callback) {
                cssLoaded(function () {
                    callback();
                });
            }
        });
    }
};