(function($) {
    if (!$("html.ie").length && !$("html.no-sr").length) {
        window.sr = ScrollReveal({ duration: 2000 });
        if (sr.isSupported()) {
            document.documentElement.classList.add('sr');
            $("section").each(function(s){
                let c = $(this).attr("class").split(' ')[0];
                s = s + 1;
                sr.reveal("."+c+":nth-child("+s+") [data-reveal]", {
                    duration: 1000,
                    easing: 'ease',
                    scale: null,
                    distance: 0,
                    delay: 300,
                    viewFactor : 0.2,
                    reset: false,
                    mobile: true,
                    container: document.getElementsByTagName("section"),
                    beforeReveal: function (domEl) {
                        $(domEl).attr("data-reveal","revealed");
                    },
                    afterReveal: function (domEl) {
                        $(domEl).removeAttr("style");
                        if($(domEl).find("[data-google-map]").length) {
                            let map = $(domEl).find("[data-google-map]");
                            $.importScript(cdnjs.googlemap_cluster,function () {
                                $.importScript(cdnjs.googlemap.replace("APIKEY", map.data("google-map")), function(){
                                    map.nl_google_map("init");
                                    map.nl_google_map("markers_add");
                                });
                            });
                        }
                    }
                },0);
                $(this).find("[data-reveal-group]").each(function(i){
                    let g = $(this).attr("class").split(' ')[0];
                    sr.reveal("."+c+":nth-child("+s+") ."+g+" [data-reveal]", {
                        duration: 500,
                        easing: 'ease',
                        scale: null,
                        distance: 0,
                        delay: 150,
                        viewFactor : 0.2,
                        reset: false,
                        mobile: true,
                        container: document.getElementsByTagName("section"),
                        beforeReveal: function (domEl) {
                            $(domEl).attr("data-reveal","revealed");
                        },
                        afterReveal: function (domEl) {
                            $(domEl).removeAttr("style");
                        }
                    },300);
                });
            });
        } else {
            document.documentElement.classList.add('no-sr');
        }
    }
})(jQuery);