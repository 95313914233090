(function($){
    const $fn = $(".comp_slider_image");

    if ($fn.length) {
        $.lib_flickity(function() {
            $.importScript(cdnjs.flickity_sync,function () {
                let $slider = $fn.find('[data-visual-slider]'),
                    $caption = $fn.find('[data-visual-caption]'),
                    autoplay = $slider.data("visual-slider"),
                    $nav = $fn.find(".wrp_comp_nav");

                if($caption.children().length > 1) {
                    $caption.flickity({
                        contain: true,
                        pageDots: false,
                        prevNextButtons: false,
                        wrapAround: true,
                        autoPlay: autoplay,
                        adaptiveHeight: true,
                        pauseAutoPlayOnHover: true
                    });
                }

                if($slider.children().length > 1) {
                    $slider.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: false,
                        pageDots: false,
                        wrapAround: true,
                        contain: true,
                        sync: $caption[0],
                        prevNextButtons: false
                    });

                    let $slider_img = $slider.find('.elm_item .elm_item_image'),
                        docStyle = document.documentElement.style,
                        transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                    let flkty = $slider.data('flickity');

                    $slider.on('scroll.flickity', function () {
                        flkty.slides.forEach(function (slide, i) {
                            let $img = $slider_img[i],
                                x = (slide.target + flkty.x) * -1 / 3;

                            $img.style[transformProp] = 'translateX(' + x + 'px)';
                        });
                    });
                }
                else {
                    $nav.remove();
                }

                $nav.on("click","[data-arrow]",function (e) {
                    e.stopPropagation();
                    $slider.flickity($(this).data("arrow"));
                    $caption.flickity("pausePlayer");
                    $caption.flickity("unpausePlayer");
                })
            })
        });
    }
})(jQuery);