(function ($) {
    let $fn = $(".comp_slider_services");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]"),
            $nav = $fn.find(".wrp_comp_nav");

        if($slider.children().length < 5){
            $slider.addClass("flickity-disabled-t");
            $nav.addClass("only--m");

        }
        $.lib_flickity(function () {
            $slider.flickity({
                pageDots: false,
                wrapAround: true,
                prevNextButtons: false,
                cellAlign: "left",
                watchCSS: true,
            });

            $nav.on("click","[data-arrow]",function (e) {
                e.stopPropagation();
                $slider.flickity($(this).data("arrow"));
            })
        });
    }
})(jQuery);