var reInitGCaptcha;

(function($) {
    fn_nl_lib_anchor();

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LfXKPYUAAAAAAN76cZqe16arph-RTztUK4cT7mT").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LfXKPYUAAAAAAN76cZqe16arph-RTztUK4cT7mT', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    cssLoaded(function () {
        $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $(".part_ui_btn").nl_lib_ripple();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }

        });
    });

    doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
        e.preventDefault();

        var submitButton = $(this).find('button[type="submit"]');
        if(submitButton.length) {
            submitButton.attr('disabled','disabled');
            submitButton.addClass('mod--loading');
        }

        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }
                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }
                });
            }
        });
    });

    doc.on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
                $('#snippet-gallery').createGallery();
            }
        });
    });
    
     doc.on('click','a[data-component-ajax]',function(e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        $(".elm_nav .mod--active").removeClass("mod--active");
        let el = $(this);
        el.addClass("mod--active");
            
        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload, function() {
                sr.sync();
                if($("[data-gallery-list]").length) {
                    $('[data-gallery-list]').createGallery();
                }
            });

        });
    });
    
 /*   doc.on('click', '[data-ajax]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
                $('#snippet-services').createGallery();
            }
        });
    });*/
    
    doc.on('change', '[data-page-select]', function (e) {
        let link = '?do=changeservices&pageId=' + $(this).val();
        $.ajax({
            url: link,
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
            }
        });
    });
    
    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.importScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        html.addClass("safari");
    }
    
    $('#snippet-contactform').antiSpam();


    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
})(jQuery);