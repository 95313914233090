(function ($) {
    let $fn = $(".comp_site");

    if ($fn.length) {
        let $gallery = $fn.find("[data-gallery-list]");

        if($gallery.length){
            $gallery.createGallery();
        }


    }
})(jQuery);