(function($) {
    let fn = ".part_ui_number";
    let val;

    doc.on("click", fn + " [data-number]", function (e) {
        e.preventDefault();
        let inp = $(this).closest(fn).find("input"),
            num;
        if ($(this).is('[data-number="plus"]')) {
            num = +inp.val() + inp.data('step');
            if (num <= inp.attr('max')) {
                inp.val(num).change();
            }
        } else if ($(this).is('[data-number="minus"]')) {
            num = +inp.val() - inp.data('step');
            if (num >= inp.attr('min')) {
                inp.val(num).change();
            }
        }
    });

    doc.on("click", fn + " input", function () {
        val = $(this).val();
        $(this).val("");
        $(this).blur(function () {
            let val_change = $(this).val();
            if (val_change === "") {
                $(this).val(val);
            }
        });
    });

    doc.on("change", fn + " input", function () {
        val = parseInt($(this).val());
        if (val % $(this).data('step') !== 0) {
            $(this).val(val + $(this).data('step') - val % $(this).data('step'));
        }
    });
})(jQuery);